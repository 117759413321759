@tailwind base;
@tailwind components;
@tailwind utilities;

/* p {
    @apply font-sans; 
} */

@media (max-width:1200px) {
    .container {
        padding-left: 20px;
        padding-right: 20px;
    }
}

 
/* h1,
h2 {
    font-family: 'Roboto Slab';
}

p {
    font-family: 'Roboto';
}

a {
    font-family: 'Roboto';
} */


@media (max-width: 450px) {
  
     .leftFashion .leftFashion .Sale {
       width: 100%;
     }
}