.ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  padding: 8px;
  border-radius: 8px;
  background-color: rgb(249 250 251);
}

.ql-container.ql-snow {
  border-radius: 10px;
}

.quill {
  width: 100%;
}

.ql-editor {
  box-sizing: border-box;
  line-height: 1.42;
  height: 100%;
  outline: none;
  overflow-y: auto;
  padding: 12px 15px;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
  background-color: rgb(249 250 251);
  border-radius: 10px;
}

.DFlex {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 450px) {
  .DFlex {
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;
  }
     
}

@media (min-width: 451px) and (max-width: 991px) {
.leftFashion {
    margin: auto;
  }
  
}
@media (min-width: 768px) and (max-width: 1024px) {
  .DFlex {
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;
  }
}

@media (max-width: 370px) {
  .offer_name {
    right: -7% !important;
  }
  
}

body {
  overflow-x: hidden;
}


@media (min-width:1024px) and (max-width:1700px) {
  .container {
    padding: 0px 50px 0px 50px;
  }

  .cashpointBanner {
    background-position: center !important;
  }
}

@media   (max-width: 393px) {
 .button_signin{
  font-size: 12px;
  
 }
 .profileImageTesti{
  margin: auto;
   
 }
  
}
