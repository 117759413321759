/* src/Sidebar.css */
:root {
    --sidebar-bg: #343a40;
    --primary-color: #007bff;
    --text-color: #ffffff;
    --sidebar-hover: #495057;
}

.sidebar {
    height: 100%;
    width: 250px;
    position: fixed;
    z-index: 1;
    top: 72px;
    left: 0;
    background-color: #025D89;
    overflow-x: hidden;
    transition: 0.3s;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    white-space: nowrap;
}

.sidebar-header {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e0e0e0;
}

.sidebar-header h3 {
    margin: 0;
    font-size: 1.2em;
    color: var(--primary-color);
}

.toggle-btn {
    background: none;
    border: none;
    color: var(--text-color);
    font-size: 20px;
    cursor: pointer;
    transition: 0.2s;
}

.toggle-btn:hover {
    color: #FFFFFF;
}

.sidebar a {
    padding: 15px 25px;
    text-decoration: none;
    font-size: 16px;
    color: #9ABED0;
    display: flex;
    align-items: center;
    transition: 0.2s;
}

.sidebar a:hover {
    background-color: #ffffff54;
    color: #FFFFFF;
}

.sidebar a i {
    min-width: 30px;
    font-size: 20px;
}

#main {
    transition: margin-left 0.3s;
    padding: 20px;
    margin-left: 250px;
}

.sidebar.closed {
    width: 80px;
}

.sidebar.closed .sidebar-header h3 {
    display: none;
}

.sidebar.closed a span {
    display: none;
}

.sidebar.closed~#main {
    margin-left: 70px;
}

@media screen and (max-width: 576px) {
    .space-y-1 {
        background-color: #ffffff;
        position: relative;
        z-index: 2;
    }
}


@media screen and (max-width: 768px) {
    .sidebar {
        width: 70px;
    }

    .sidebar .sidebar-header h3 {
        display: none;
    }

    .sidebar a span {
        display: none;
    }

    #main {
        margin-left: 70px;
    }

    .sidebar.open {
        width: 250px;
    }

    .sidebar.open .sidebar-header h3 {
        display: block;
    }

    .sidebar.open a span {
        display: inline;

        
    }

    .sidebar.open~#main {
        margin-left: 250px;
    }
}

#main{
    position: relative;
    z-index: 0;
}
/* .active {
    background-color: #ffffff54;
    color: #FFFFFF !important;
} */